import { Controller } from "@hotwired/stimulus"
import Chart from "chart.js/auto";

export default class extends Controller {
  static targets = ["canvas"];
  static values = { chartData: Array };

  connect() {
    console.log(this.identifier);
    this.updateChart();
  }

  updateChart() {
    const ctx = this.canvasTarget.getContext('2d');

    if (this.chart) {
      this.chart.destroy();
    }

    this.chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: this.chartDataValue.map(data => data.imported_date),
        datasets: [
          {
            label: 'Processing Time',
            data: this.chartDataValue.map(data => Math.round((data.avg_processing_time || 0) / 60)),
            backgroundColor: 'rgba(255, 99, 132, 0.6)',
            stack: 'Stack 0',
          },
          {
            label: 'Approval Time',
            data: this.chartDataValue.map(data => Math.round((data.avg_approval_time || 0) / 60)),
            backgroundColor: 'rgba(75, 192, 192, 0.6)',
            stack: 'Stack 0',
          },
          {
            label: 'Upload Queue Time',
            data: this.chartDataValue.map(data => Math.round((data.avg_upload_queue_time || 0) / 60)),
            backgroundColor: 'rgba(255, 206, 86, 0.6)',
            stack: 'Stack 0',
          },
          {
            label: 'Upload Time',
            data: this.chartDataValue.map(data => Math.round((data.avg_upload_time || 0) / 60)),
            backgroundColor: 'rgba(153, 102, 255, 0.6)',
            stack: 'Stack 0',
          },
        ]
      },
      options: {
        scales: {
          x: {
            title: {
              display: true,
              text: 'Imported Date'
            },
          },
          y: {
            stacked: true,
            position: 'right',
            min: 0,
            max: 1000,
            title: {
              display: true,
              text: 'Time (minutes)'
            }
          }
        }
      }
    });
  }

}

