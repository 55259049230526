import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ff-pay--stripe"
export default class extends Controller {
  static values = {
    jumpLink: String,
    publishableKey: String
  }

  connect() {
    console.log(this.identifier);
    this.stripe = Stripe(this.publishableKeyValue);

    document.addEventListener('DOMContentLoaded', () => {
      this.showCheckoutIfRequested();
    });
  }

  async initializeCheckout() {
    console.log("Initialise")
    const url = `/ff_pay/stripe/j/${this.jumpLinkValue}/session`
    const response = await fetch(url, { method: "POST" });
    const { clientSecret } = await response.json();

    this.checkout = await this.stripe.initEmbeddedCheckout({
      clientSecret,
    });

    this.checkout.mount('#checkout');
  }

  showCheckoutIfRequested() {
    // Check if the URL contains the "show-checkout" parameter
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("show-checkout")) {
      this.showCheckout();
    }
  }

  showCheckout() {
    console.log("showCheckout")
    //send event to show the stripe modal controller
    this.dispatch("show-stripe-modal", { detail: {} })

    if (!this.checkout) {
      this.initializeCheckout();
    }

    gtag("event", "begin_checkout", {
      currency: "GBP",
      value: 100,
      items: [
      {
        item_id: "unlock_01",
        item_name: "Unlock Video"
      }
      ]
    });
  }
}
