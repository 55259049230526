import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="booking--lock"
export default class extends Controller {
  static targets = ["lockButton"];
  static values = { locked: Boolean };
  static outlets = ["booking--row"]

  connect() {
    console.log(this.identifier);
  }

  toggleLock() {
    this.lockedValue = !this.lockedValue;
  }


}
