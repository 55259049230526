import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal"];

  connect() {
    console.log(this.identifier);
  }

  showModal() {
    this.modalTarget.classList.remove("hidden")
    if (this.modalTarget.requestFullscreen) {
      this.modalTarget.requestFullscreen();
    } else if (this.modalTarget.mozRequestFullScreen) {
      this.modalTarget.mozRequestFullScreen();
    } else if (this.modalTarget.webkitRequestFullscreen) {
      this.modalTarget.webkitRequestFullscreen();
    } else if (this.modalTarget.msRequestFullscreen) {
      this.modalTarget.msRequestFullscreen();
    }
  }

  hideModal() {
    this.modalTarget.classList.add("hidden")
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }
}

