import { Controller } from "@hotwired/stimulus"

import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgVideo from 'lightgallery/plugins/video'


// Connects to data-controller="video-gallery"
export default class extends Controller {
  static values = {
    uuid: String,
    paid: Boolean
  }

  connect() {
    console.log(this.identifier);

    const gallery = lightGallery(this.element, {
      licenseKey: "63B20EC2-C8FA-405E-8363-5B3FA028D2AC",
      autoplayFirstVideo: true,
      pager: true,
      customSlideName: true,
      galleryId: "video",
      download: this.paidValue,
      plugins: [lgVideo, lgThumbnail],
      videojs: true,
      selector: ".vid",
      mobileSettings: {
        controls: false,
        showCloseIcon: true,
        download: this.paidValue,
        rotate: false
      }
    });

    this.openSelectedSlide(gallery)
  }

  openSelectedSlide(gallery) {
    if (!this.hasUuidValue) {
      return
    }

    const slides = this.element.querySelectorAll('.vid');

    // Find the index of the first slide that matches the UUID
    const slideIndex = Array.from(slides).findIndex(slide => slide.getAttribute('data-slide-name') === this.uuidValue);

    // If a matching slide is found, open the gallery at that slide
    if (slideIndex !== -1) {
      gallery.openGallery(slideIndex);
    }
  }
}
