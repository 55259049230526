import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="booking--row"
export default class extends Controller {
  static targets = ["submitIcon", "checkbox"];

  connect() {
    console.log(this.identifier);
  }

  paidDidChange() {
    // Display loading animation
    this.checkboxTarget.classList.add('hidden');
    this.submitIconTarget.classList.remove('hidden');

    // Submit the form
    this.element.requestSubmit()
  }

}
