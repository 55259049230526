//Disable Prefetch on redirect links - they're going to a different domain, so won't work anyway
document.addEventListener("turbo:before-prefetch", (event) => {
  try {
    // Attempt to parse the URL from the event's target element
    const url = new URL(event.target.href);

    // Skip urls for redirect controller
    if (url.pathname.startsWith("/redirect/")) {
      event.preventDefault();
    }
  } catch (error) {
    console.error("Invalid prefetch URL:", error);
  }
})