import { Controller } from "@hotwired/stimulus"
import Chart from "chart.js/auto";

export default class extends Controller {
  static targets = ["canvas"];
  static values = { chartData: Array };

  connect() {
    console.log(this.identifier);
    this.updateChart();
  }

  updateChart() {
    const ctx = this.canvasTarget.getContext('2d');

    if (this.chart) {
      this.chart.destroy();
    }

    this.chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: this.chartDataValue.map(data => data.imported_date),
        datasets: [
        {
          label: 'Uploaded',
          data: this.chartDataValue.map(data => data.completed_uploads_count),
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
          stack: 'Stack 0'
        },
        {
          label: 'Total',
          data: this.chartDataValue.map(data => data.total_jumps - data.completed_uploads_count),
          backgroundColor: 'rgba(255, 99, 132, 0.6)',
          stack: 'Stack 0'
        }

        ]
      },
      options: {
        scales: {
          x: {
            title: {
              display: true,
              text: 'Imported Date'
            },
          },
          y: {
            stacked: true, // Enabling stacked bars
            title: {
              display: true,
              text: 'Count'
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function(context) {
                const label = context.dataset.label || '';
                if (label === 'Total') {
                  const realValue = context.raw + context.chart.data.datasets[0].data[context.dataIndex];
                  return `Total Jumps: ${realValue}`;
                } else {
                  return `${label}: ${context.raw}`;
                }
              }
            }
          }
        }
      }
    });
  }
}
