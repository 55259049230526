import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ff-pay--return"
export default class extends Controller {
  static values = {jumpLink: String, sessionId: String}
  static targets = ["success", "paymentFailure", "otherFailure", "processing"]

  connect() {
    this.getSessionStatus()
  }

  getSessionStatus() {

    const url = `/ff_pay/stripe/j/${this.jumpLinkValue}/session/${this.sessionIdValue}`
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        this.hideProcessing();
        switch (data.status) {
          case 'complete':
            this.showSuccess(data.id);
            break;
          case 'payment_failed': // Assuming 'payment_failed' is the status for payment failure
            this.showPaymentFailure();
            break;
          default:
            this.showOtherFailure();
            break;
        }
      })
      .catch(error => {
        console.error('There has been a problem with your fetch operation:', error);
        this.hideProcessing();
        this.showOtherFailure();
      });
  }

  hideAllSections() {
    this.successTarget.classList.add("hidden");
    this.paymentFailureTarget.classList.add("hidden");
    this.otherFailureTarget.classList.add("hidden");
    this.processingTarget.classList.add("hidden");
  }


  hideProcessing() {
    this.processingTarget.classList.add("hidden");
  }

  showSuccess(id) {
    this.successTarget.classList.remove("hidden");

    gtag("event", "purchase", {
      currency: "GBP",
      value: 100,
      transaction_id: id,
      items: [
      {
        item_id: "unlock_01",
        item_name: "Unlock Video"
      }
      ]
    });
  }

  showPaymentFailure() {
    this.paymentFailureTarget.classList.remove("hidden");
  }

  showOtherFailure() {
    this.otherFailureTarget.classList.remove("hidden");
  }

}
