import { Controller } from "@hotwired/stimulus"


// Connects to data-controller="photo-download"
export default class extends Controller {
  static targets = ['downloadLink', 'downloadingLink'];

  connect() {
    console.log(this.identifier);
  }


  download() {
    this.downloadLinkTarget.classList.add('hidden');
    this.downloadingLinkTarget.classList.remove('hidden');
  }


}
