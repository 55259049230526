import { Controller } from "@hotwired/stimulus"
import { decodeBlurHash } from 'fast-blurhash';

// Connects to data-controller="blurhash"
export default class BlurhashController extends Controller {
  static values = {
    blurhash: String,
    backgroundSize: { type: String, default: "100% 100%" },
    punch: { type: Number, default: 1 },
    x: { type: Number, default: 32 },
    y: { type: Number, default: 32 },
  };

  connect() {
    console.log(this.identifier);
    this.draw();
  }

  draw() {
    const width = this.xValue;
    const height = this.yValue;

    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;

    const pixels = decodeBlurHash(this.blurhashValue, width, height, this.punchValue);
    const drawContext = canvas.getContext("2d");
    const imageData = drawContext.createImageData(width, height);
    imageData.data.set(pixels);
    drawContext.putImageData(imageData, 0, 0);

    this.element.style.backgroundSize = this.backgroundSizeValue;
    this.element.style.backgroundImage = `url(${canvas.toDataURL("image/png")})`;
  }

}
