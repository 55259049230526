import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="booking--search"
export default class extends Controller {
  static targets = ["dateFromField", "dateToField"];

  connect() {
    console.log(this.identifier);
    this.timeout = null;

    this.removeClearParam();
  }

  //clear button sets clear=true when resetting the form
  //this means a reload would lose any subsequently entered search state
  //setting a redirect caused the table to clear (wierd morphing issue)
  //so we just accept the param in the url, and remove it manually
  removeClearParam() {
    // Create a URL object based on the current window location
    const url = new URL(window.location);

    // Access the URL's search parameters
    const params = url.searchParams;

    // Check if the 'clear' parameter is present and set to 'true'
    if (params.get('clear') === 'true') {
      // Remove the 'clear' parameter
      params.delete('clear');

      // Update the URL without the 'clear' parameter
      // Use history.replaceState to avoid adding a history entry
      window.history.replaceState({}, '', url.toString());
    }
  }

  search() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.submit();
    }, 500);  // 500ms delay for throttling
  }


  submit() {
    this.element.requestSubmit();
  }
}

