import { Controller } from "@hotwired/stimulus"
import Chart from "chart.js/auto";

export default class extends Controller {
  static targets = ["canvas"];
  static values = { chartData: Array };

  connect() {
    console.log(this.identifier);
    this.updateChart();
  }

  updateChart() {
    const ctx = this.canvasTarget.getContext('2d');

    if (this.chart) {
      this.chart.destroy();
    }

    this.chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: this.chartDataValue.map(data => data.imported_date),
        datasets: [
          {
            label: 'Clip Assignment Correct (%)',
            data: this.chartDataValue.map(data => data.clip_assignment_correct_percentage),
            borderColor: 'rgba(255, 99, 132, 1)',
            fill: false,
          },
          {
            label: 'Edit Correct (%)',
            data: this.chartDataValue.map(data => data.edit_correct_percentage),
            borderColor: 'rgba(75, 192, 192, 1)',
            fill: false,
          },
        ]
      },
      options: {
        scales: {
          x: {
            title: {
              display: true,
              text: 'Imported Date'
            },
          },
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: 'Accuracy (%)'
            }
          },
        }
      }
    });
  }
}
