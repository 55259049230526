import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sharing"
export default class extends Controller {
  static targets = ["overlay", "slidingDiv"];
  static values = {
    url: String
  }

  connect() {
    console.log(this.identifier);
    this.element[this.identifier] = this
    // You can set up any initial configurations here if needed.
  }

  share(url) {
    console.log("Did share: "+url);
    this.urlValue = url


    if (navigator.share) {
  // Web Share API is supported
      this.nativeShare()
    } else {
      this.open()
    }
  }

  open() {
    this.overlayTarget.classList.remove("hidden");
    this.slidingDivTarget.classList.add("slide-up");
    console.log("Open "+this.urlValue);
  }

  close() {
    event.preventDefault()
    this.overlayTarget.classList.add("hidden");
    this.slidingDivTarget.classList.remove("slide-up");
    console.log("Close "+this.urlValue);
  }



  nativeShare() {
    navigator.share({
      title: 'Check out my skydive!',
      url: this.urlValue + "?s=n"
    }).then(() => {
      console.log('Did Share!');
    })
    .catch(console.error);
  }

  clickedClose(event) {
    event.preventDefault()
    this.close()
  }

  facebook(event) {
    event.preventDefault()
    this.close()

    const facebookBaseUrl = '//www.facebook.com/sharer/sharer.php?u=';
    const url =  facebookBaseUrl + encodeURIComponent(this.urlValue + "?s=f");

    console.log("Share: "+url)

    window.open(url, '_blank');


  }

  email(event) {
    event.preventDefault()

    this.close()

    var subject = "Check out my skydive";
    var body = "Hey, check out my skydive here: \n\n" + this.urlValue + "?s=m" ;

    var mailtoLink = "mailto:?subject=" + encodeURIComponent(subject) + "&body=" + encodeURIComponent(body);

    console.log("Share: "+mailtoLink)

    window.open(mailtoLink);

  }

  copy(event) {
    event.preventDefault()
    this.close()

    const linkToCopy = this.urlValue + "?s=c"
    console.log("Share: "+linkToCopy)

    navigator.clipboard.writeText(linkToCopy).then(function() {
      console.log("URL copied to clipboard successfully!");
    }).catch(function(error) {
      console.error("Unable to copy URL to clipboard.", error);
    });

  }

}
