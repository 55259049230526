import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="share-item"
export default class extends Controller {
  static values = {
    url: String
  }
  static targets = [ "sharing" ]

  connect() {
    console.log(this.identifier);
  }

  share(event) {

    const url = this.urlValue || window.location.href;
    document.querySelector('#sharing').sharing.share(url)

    event.preventDefault()
  }
}
