import { Controller } from "@hotwired/stimulus";
import Chart from "chart.js/auto";

export default class extends Controller {
  static targets = ["canvas"];
  static values = { chartData: Array };

  connect() {
    console.log(this.identifier);
    this.updateChart();
  }

  updateChart() {
    const ctx = this.canvasTarget.getContext('2d');
    const dropzoneDatasets = this.prepareDropzoneDatasets();

    if (this.chart) {
      this.chart.destroy();
    }

    this.chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: this.getFullDateRange(),
        datasets: dropzoneDatasets
      },
      options: {
        scales: {
          x: {
            title: {
              display: true,
              text: 'Imported Date'
            }
          },
          y: {
            stacked: true, // Enabling stacked bars
            title: {
              display: true,
              text: 'Jumps Count'
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                const label = context.dataset.label || '';
                return `${label}: ${context.raw}`;
              }
            }
          }
        }
      }
    });
  }

  // Call this from a button click to hide all series
  deselectAll() {
    if (this.chart) {
      this.chart.data.datasets.forEach(dataset => {
        dataset.hidden = true;
      });
      this.chart.update();
    }
  }

  selectAll() {
    if (this.chart) {
      this.chart.data.datasets.forEach(dataset => {
        dataset.hidden = false;
      });
      this.chart.update();
    }
  }


  // Generate a list of dates from the earliest to the latest
  getFullDateRange() {
    const dates = this.chartDataValue.map(data => new Date(data.imported_date + 'T00:00:00Z'));
    if (!dates.length) return [];

    const minDate = new Date(Math.min(...dates));
    const maxDate = new Date(Math.max(...dates));
    minDate.setUTCHours(0, 0, 0, 0);  // Reset time to start of the day in UTC
    maxDate.setUTCHours(0, 0, 0, 0);  // Also reset maxDate to start of the day in UTC

    const dateList = [];

    // Use UTC date methods to avoid timezone issues
    for (let dt = new Date(minDate); dt <= maxDate; dt.setUTCDate(dt.getUTCDate() + 1)) {
      dateList.push(dt.toISOString().split('T')[0]);  // Format in ISO but split to get the date part only
    }
    return dateList;
  }




  prepareDropzoneDatasets() {
    const dropzones = {};
    // Organize data by dropzone
    this.chartDataValue.forEach(data => {
      if (!dropzones[data.dropzone_name]) {
        dropzones[data.dropzone_name] = [];
      }
      dropzones[data.dropzone_name].push(data);
    });

    const fullDates = this.getFullDateRange(); // Use the full range of dates
    const totalDropzones = Object.keys(dropzones).length;

    return Object.keys(dropzones).map((dzName, index) => ({
      label: dzName,
      data: fullDates.map(date => {
        const dataForDate = dropzones[dzName].find(d => d.imported_date === date);
        return dataForDate ? dataForDate.total_jumps : 0;
      }),
      backgroundColor: this.getColorByIndex(index, totalDropzones), // Use OKLCH color
      stack: 'Stack 0'
    }));
  }


  // Function to generate OKLCH color based on index
  getColorByIndex(index, total) {
    const hue = (360 * index / total) % 360;  // Distribute hues evenly across 360 degrees
    const lightness = 75;  // Typically between 0 and 100
    const chroma = 0.4;    // Typically between 0 and 1
    const alpha = 0.6;     // Transparency

    // Return an OKLCH color string in CSS format
    return `oklch(${lightness}% ${chroma} ${hue} / ${alpha})`;
  }
}

