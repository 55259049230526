import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dispatch"
// Used to send an event to another controller easily.
// Specify the event in the id parameter
// data:{controller: "dispatch", action: "dispatch#send:prevent", dispatch_id_param: "stripe-buy"} do
export default class extends Controller {
  connect() {
  }

  send(event) {
    this.dispatch(event.params.id, { detail: { } })
    console.log("Send:"+event.params.id)
  }
}
