import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="booking--magic"
export default class extends Controller {
  static targets = ["email", "mobile", "name"];

  connect() {
    console.log(this.identifier);
  }

  didPaste(event) {
    this.splitByTabs(event.target);

    this.parseData(event.target);
  }

  splitByTabs(target) {
    const data = target.value;

    // Convert tabs to line breaks. Remove empty lines
    const split = data.replace(/\t/g, "\n")
    target.value = split;
  }

  parseData(target) {
    const data = target.value

    // Map to trim each line and then filter out empty lines
    const inputLines = data.split(/\r?\n/).map(line => line.trim()).filter(line => line !== "");

    let emailLine, phoneLine, nameLine;

    const ignoredWords = ['email', 'address', 'phone', 'number', 'name'];

    const validLines = inputLines.filter(line => {
      return !ignoredWords.some(word => line.toLowerCase().includes(word));
    });

    for (let line of validLines) {
      if (line.length < 4) {
        continue;
      }

      if (this.isPlausiblyDate(line)) {
        continue;
      }

      if (line.includes("@")) {
        emailLine = line;
      } else if (this.isPlausiblyPhoneNumber(line)) {
        phoneLine = line;
      } else {
        nameLine = line;
      }
    }

    if (emailLine) {
      this.emailTarget.value = emailLine;
    }

    if (phoneLine) {
      this.mobileTarget.value = phoneLine;
    }

    if (nameLine) {
      this.nameTarget.value = nameLine;
    }
  }

  isPlausiblyDate(string) {
    // Regular expression to match the date pattern d/m/y
    // \b for word boundary, \d{1,2} for one or two digits, and \d{2} for exactly two digits
    const pattern = /\b\d{1,2}\/\d{1,2}\/\d{2}\b/;
    return pattern.test(string);
  }

  isPlausiblyPhoneNumber(string) {
    //length > 8
    //more than half of string is digits
    return string.length > 8 && string.replace(/[^\d]/g, '').length > string.length / 2
  }


}

